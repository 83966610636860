import Logo from "../assets/v11.png";

import AnılEmreDaldal from "../assets/Anıl.jpg";
import CihanYoldas from "../assets/Cihan.jpg";
import EmreAslan from "../assets/Emre.jpg";
import YagmurVardar from "../assets/Yagmur.jpg";
import Harun from "../assets/Harun.jpg";
import Sozel from "../assets/Sozel.jpg";

import InstagramIcon from "../assets/footerInstagram.svg";
import LinkedinIcon from "../assets/footerLinkedin.svg";
import TwitterIcon from "../assets/footerTwitter.svg";

import Biletinial from "../assets/biletinial.png";
import Jerfl from "../assets/jerf.png";
import KenevirCo from "../assets/kenevirco.png";
import Taleworlds from "../assets/taleworlds.png";
import Muhiko from "../assets/muhiko.png";
import Hop from "../assets/hop.png";
import YvesRocher from "../assets/yvesrocher.png";
import Dardanel from "../assets/dardanel.png";
import MedusaAtelier from "../assets/medusaatelier.png";
import SerinceCigKofte from "../assets/serincecigkofte.png";

export const eventProviderLink = "https://www.acmhacettepe.com";
export const eventProvider = "ACM Hacettepe";
export const eventName = (
  <>
    Gelişim <span>V11</span>
  </>
);
export const eventDate = "16-17 Aralık'ta!";
export const eventLogo = Logo;
export const eventDetails =
  "ACM Hacettepe Öğrenci Topluluğu olarak her sene düzenlediğimiz GELİŞİM etkinliği, 16-17 Aralık 2024 tarihlerinde düzenlenecek GELİŞİM V11 ile devam ediyor. Her sene olduğu gibi bu yılki GELİŞİM etkinliği de alanında uzman katılımcıların tecrübeleri, geçmişleri ve bilgi birikimleri üzerine yapacakları sunumlarla öğrencilerin hayatlarına farklı bakış açıları getirmeyi hedefliyor.";
export const contactMessage =
  "Etkinlik hakkında herhangi bir istek, öneriniz veya merak ettikleriniz hakkında bize mail yolu ile ulaşabilirsiniz.";
export const contactMailLink = "mailto:iletisim@acmhacettepe.com";

export const navigationItems = [
  {
    name: "Anasayfa",
    to: "#home",
  },
  {
    name: "Hakkımızda",
    to: "#about",
  },
  {
    name: "Konuşmacılar",
    to: "#participants",
  },
  {
    name: "Takvim",
    to: "#calendar",
  },
];

export const footerSocialLinks = [
  {
    link: "https://twitter.com/acmhacettepe?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    icon: TwitterIcon,
  },
  {
    link: "https://www.instagram.com/acmhacettepe/",
    icon: InstagramIcon,
  },
  {
    link: "https://www.linkedin.com/company/acm-hacettepe/mycompany/",
    icon: LinkedinIcon,
  },
];

export const dates = ["16 Aralık", "17 Aralık"];

export const participants = [
  {
    name: "Sözel Tim & Deniz Altun",
    description: "Video İçeriği Üreticisi & Film Yapımcısı",
    date: dates[0],
    time: "12.00",
    detail: "",
    img: Sozel,
  },
  {
    name: "Harun Yılmaz",
    description: "Sporcu",
    date: dates[0],
    time: "13.30",
    detail: "",
    img: Harun,
  },
  {
    name: "Emre Aslan",
    description: "Oyuncu",
    date: dates[0],
    time: "15.00",
    detail: "",
    img: EmreAslan,
  },
  {
    name: "Cihan Yoldaş",
    description: "İçerik Üreticisi-Komedyen",
    date: dates[1],
    time: "12.00",
    detail: "",
    img: CihanYoldas,
  },
  {
    name: "Yağmur Vardar",
    description: "Video İçerik Üreticisi",
    date: dates[1],
    time: "13.30",
    detail: "",
    img: YagmurVardar,
  },
  {
    name: "Anıl Emre Daldal",
    description: "Sanatçı",
    date: dates[1],
    time: "15.00",
    detail: "",
    img: AnılEmreDaldal,
  },

];

export const sponsors = [
  Biletinial,
  Jerfl,
  KenevirCo,
  Taleworlds,
  Muhiko,
  Hop,
  YvesRocher,
  Dardanel,
  MedusaAtelier,
  SerinceCigKofte,
];

export const colors = {
  primaryColor: "#377373",
  secondaryColor: "#fcdec2",
  secondaryColorLight: "rgba(95, 126, 222, .1)",
  textColor: "rgba(255, 255, 255, .5)",
  borderColor: "rgba(255, 255, 255, .1)",
  popupColor: "#F0F3FB",
  popupOverlayColor: "rgba(0, 0, 0, .3)",
  dark300: "#22202B",
  dark400: "#1D1B24",
  dark500: "#17151D",
};
